@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* elements */
  :root {
    @apply bg-gray-100;
  }
  h1,
  h2,
  h3 {
    @apply font-headline text-left mb-2;
  }
  h1,
  h2 {
    @apply font-bold;
  }
  h1 {
    @apply text-4xl sm:mb-8;
  }
  h2 {
    @apply text-3xl sm:mb-4;
  }
  h3 {
    @apply text-2xl font-semibold sm:mb-3;
  }
  p,
  a,
  label,
  li,
  button,
  address {
    @apply font-content;
  }
  input,
  select,
  textarea {
    min-height: 44px;
    @apply py-2 px-3 rounded-lg border-2 border-gray-800 focus:outline-black bg-gray-50;
  }
  input[type='checkbox'] {
    min-height: auto;
    accent-color: rgb(185, 28, 28);
  }
  /* classes */
  .btn {
    @apply rounded-full inline-block shadow-lg hover:shadow-none text-center disabled:cursor-not-allowed;
    @apply disabled:bg-gray-500;
  }
  .btn--large {
    @apply h-11 px-6 text-lg py-2;
  }
  .btn--small {
    @apply h-9 px-5 text-sm tracking-wide py-2;
  }
  .btn--round {
    @apply h-11 w-11 text-lg py-2;
  }
  .btn--primary {
    @apply bg-red-800 hover:bg-red-700 text-gray-100;
    @apply disabled:bg-gray-500;
  }
  .btn--secondary {
    @apply bg-gray-700 hover:bg-gray-500 text-gray-100;
    @apply disabled:bg-gray-500;
  }
  .btn--light {
    @apply bg-gray-100 text-gray-800 font-medium hover:bg-gray-500 hover:text-gray-100;
    @apply disabled:bg-gray-500;
  }
  .btn--warning {
    @apply bg-yellow-600 hover:bg-yellow-500 text-gray-100;
    @apply disabled:bg-gray-500;
  }
  .btn--responsive-mx {
    @apply mx-auto sm:mx-0 sm:w-auto;
  }
  .anchor {
    @apply underline text-red-800 hover:text-red-500;
  }
  .anchor-dark {
    @apply underline hover:text-red-700;
  }
  .anchor-light {
    @apply underline text-gray-100 hover:text-red-300;
  }
  .link {
    @apply pb-2 hover:text-red-700 border-b-2 border-gray-300 hover:border-red-700 hover:border-current;
  }
  .link-selected {
    @apply text-red-700 border-b-2 border-red-700 pb-2;
  }
  /* media queries */
  @media (min-width: 1068px) {
    .header-full {
      @apply flex;
    }
    .header-mobile {
      @apply hidden;
    }
  }
  @media (max-width: 1067px) {
    .header-full {
      @apply hidden;
    }
    .header-mobile {
      @apply flex;
    }
  }
  /* refactor these */
  .article-viewer h3 {
    @apply text-2xl text-center mb-4;
  }
  .article-viewer h3:not(:first-of-type) {
    @apply mt-6;
  }
  .article-viewer img,
  .article-img {
    @apply mx-auto rounded-sm;
    max-height: 640px;
  }
  .article-viewer figure {
    @apply my-8;
  }
  .article-viewer figure figcaption {
    @apply text-center text-xs italic;
  }
  .article-viewer p {
    @apply mb-4;
  }
  .article-viewer a {
    @apply underline text-red-800 hover:text-red-500;
  }
}
